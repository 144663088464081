
// Core variables and mixins
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "variables";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Reset and dependencies
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

// Core CSS
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pager";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/labels";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/badges";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/media";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/panels";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/wells";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/close";

// Components w/ JavaScript
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/modals";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/carousel";

// Utility classes
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";

html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 60px;
  padding-top: 60px;
  background: #dfe4e7;
}

body.login {
  background: $brand-primary;
}

.navbar-brand {
  float: left;
  height: 50px;
  display: block;
  margin: 0 10px !important;
  text-indent: -9000px;

  body.bet & {
    background: url('/img/betcenter.png') no-repeat center center;
    width: 45px;
  }

  body.qed & {
    background: url('/img/qed.png') no-repeat center center;
    background-size: contain;
    width: 60px;
  }
}

.navbar-toggle {
  background: #FFF;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #7d8996;

  p {
    margin: 20px 0;
    color: #FFF;
  }
}

.content {
  padding: 20px 0;
}

/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */

.form-horizontal--readonly {
    .form-group {
      margin-bottom: 0;
    }
}

.panel-default {
  box-shadow: 0 0 0 5px #c4cdd2;
  border-radius: $border-radius-base;
  > .panel-heading {
    border-color: #FFF;
  }
  > .panel-heading {
    padding: 15px 15px;
  }
}

.panel {
  > .panel-heading {
    background: #7D8996;
    background-image: linear-gradient(top,#7d8996,#515c63);
    background-image: -webkit-linear-gradient(top,#7d8996,#515c63);
    background-image: -moz  -linear-gradient(top,#7d8996,#515c63);
    color: #FFF;
  }
}

.table thead th {
  background: linear-gradient(#dfe4e7 0,#c4cdd2 100%);
  background: -webkit-linear-gradient(#dfe4e7 0,#c4cdd2 100%);
  background: -moz-linear-gradient(#dfe4e7 0,#c4cdd2 100%);
}

.table-striped > tbody > tr:nth-of-type(odd){
  background: linear-gradient(#fff,#dfe4e7);
  background: -webkit-linear-gradient(#fff,#dfe4e7);
}

h1 {
  margin: 0 0 20px;
}

.dropdown-menu {
  min-width: 200px;
}

tr.open.first-level td {
  background: #dfe4e7;
  font-weight: bold;
}

tr.open .glyphicon-expand:before {
  content: "\e159" !important;
}

.table--results tbody td {
  vertical-align: middle !important;
}
.table--results tbody tr.first-level td:first-child {
  border-right:1px solid #c7cbcf;
}
